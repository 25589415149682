<template>
    <div class="article-main">
        <div class="article-header">
            <h1 v-if="info.type">{{ $webConfig.articleTypes[info.type].name }}</h1>
        </div>
        <div>
            <el-divider></el-divider>
        </div>
        <div class="article-nnav">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }"><i class="el-icon-s-home"></i></el-breadcrumb-item>
                <el-breadcrumb-item v-if="info.type">{{ $webConfig.articleTypes[info.type].name }}</el-breadcrumb-item>
                <!--<el-breadcrumb-item v-if="info.title">{{info.title }}</el-breadcrumb-item>-->
            </el-breadcrumb>
            <!--<div>{{info.created_at}}</div>-->
        </div>
        <div class="article-schema" v-if="info.title">
            <h2>{{ info.title }}</h2>
            <el-divider></el-divider>
        </div>
        <div class="article-content">
            <div class="article-pre-content" v-html="info.content"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "index",
    data() {
        return {
            info: {}
        }
    },
    methods: {
        getInfo() {
            let id = this.$route.query.id;
            let type = this.$route.query.type;
            this.$axios.post("site/article/getInfo", { id, type })
                .then((res) => {
                    if (res.data.code == 1000) {
                        let info = res.data.data.info;
                        this.info = info;
                    }
                })
        },

    },
    created() {
        /**
         * 滚动条到顶部解决办法
         */
        let scrollTop = document.documentElement.scrollTo || document.body.scrollTop;
        if (scrollTop > 0) {
            window.requestAnimationFrame(scrollTop);
            window.scrollTop(0, scrollTop - scrollTo / 8);
        }
        this.getInfo();
    }
}
</script>

<style scoped>
.article-main {
    background-color: #ffffff;
    width: 100%;
    padding: 20px 0;
}

.article-header {
    text-align: center;
}

.article-nnav {
    width: 60% !important;
    margin-left: auto;
    margin-right: auto;
}

.article-nnav>div:last-child {
    height: 50px;
    font-size: 12px;
    line-height: 50px;
    font-weight: bold;
    color: #aeaeae;
}

.article-content {
    margin-left: auto;
    margin-right: auto;
    width: 50% !important;
}

.article-pre-content {
    width: 100%;
    letter-spacing: 1px;
    padding: 20px 20px;
}

.article-pre-content img {
    max-width: 100%;
}

.article-schema {
    text-align: center;
}
</style>
